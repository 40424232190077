/*
 * @Description:
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/components/baseTable/baseTableProperty.js
 */

export class btnProperty {
  type;
  label;
  size = "small";

  static Init(label, type, size) {
    let r = new this();
    r.label = label;
    size && (r.size = size);
    if (type) {
      r.type = type;
    } else {
      switch (r.label) {
        case "删除":
          r.type = "danger";
          break;
        case "查看":
        case "详情":
          r.type = "primary";
          break;
        default:
          r.type = "info";
          break;
      }
    }
    return r;
  }

  static Icon(icon, type, size) {
    let r = new this();
    r.icon = icon;
    size && (r.size = size);
    type && (r.type = type);
    return r;
  }
}

export const colType = {
  TEXT: "1",
  KEY_MAP: "2"
};

export class colProperty {
  prop;
  label;
  width;
  type;
  addition;
  // 该列是否展示
  show = true;
  showOverflowTooltip = false;

  static Init(label, prop, width, show = true) {
    let r = new this();
    r.prop = prop;
    r.label = label;
    width && (r.width = width);
    r.show = show;
    return r;
  }

  setAddition(type, addition) {
    this.type = type;
    addition && (this.addition = addition);
    return this;
  }

  asyncMap(data, childrenName) {
    if (this.type === colType.KEY_MAP && this.addition) {
      data.forEach((row) => {
        this.addition.forEach((e) => {
          // 如果该元素的值等于addition中的值 ，则显示该选项的label
          if (row[this.prop] == e.value) {
            row[this.prop + "Label"] = e.label;
          }
        });
        if (childrenName && row[childrenName]) {
          this.asyncMap(row[childrenName], childrenName);
        } else if (row.children) {
          this.asyncMap(row.children);
        }
      });
    }
  }

  setShowOverflowTooltip(showOverflowTooltip = true) {
    this.showOverflowTooltip = showOverflowTooltip;
    return this;
  }
}

export class tableProperty {
  rowKey = "id";
  // border = false;
  style;
}

export class baseTableProperty {
  tableProperty = new tableProperty();
  data = [];
  colProperty = [];
  opBtns = [];
  opBtnsWidth = null;
  treeProps;
  selection = false;
  selectable = () => true;

  // 附带分页
  pagination = true;

  // table Loading
  vLoading = false;

  setData(data, childrenName) {
    let d = JSON.parse(JSON.stringify(data));
    this.colProperty.forEach(async (e) => {
      await e.asyncMap(d, childrenName);
    });
    this.data = d;
    return this;
  }
}
